import * as React from 'react';
import { graphql } from 'gatsby';
import tw from 'twin.macro';
import { Link } from '../utils/link';
import {
  defaultLangKey,
  getPathWithLanguage,
  Language,
} from '../data/languages';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { models } from '../../models';
import { seoData } from '../data/seo';
import { FluidObject } from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import ContentWidth from '../components/contentWidth';

type Node = {
  node: {
    id: string;
    node_locale: Language;
    title: string;
    description: {
      description: string;
    };
    heroImage: {
      fluid: FluidObject;
    };
    slug: string;
    publishDate: string;
  };
};

type Props = {
  data: {
    allContentfulBlogPost: {
      edges: Node[];
    };
  };
  location: Location;
};

function Post({
  node: {
    title,
    node_locale,
    slug,
    heroImage,
    publishDate,
    description: { description },
  },
}: Node) {
  return (
    <Link
      css={tw`max-w-full md:max-w-sm w-full md:max-w-full md:flex mb-12 last:mb-0 md:min-h-40 block hover:opacity-75`}
      to={getPathWithLanguage(node_locale, models.dynamic.blogPost.path, slug)}
    >
      <BackgroundImage
        Tag="div"
        fluid={heroImage.fluid}
        fadeIn={true}
        css={tw`h-56 md:h-40 md:h-auto md:w-64 flex-none bg-cover text-center overflow-hidden bg-gray-200`}
      >
        <time
          css={tw`text-title italic bg-black bg-opacity-50 text-white absolute right-0 bottom-0 p-2 leading-none`}
        >
          {publishDate}
        </time>
      </BackgroundImage>
      <div css={tw`bg-white md:px-4 flex flex-col leading-normal`}>
        <h1 css={tw`text-gray-900 font-bold text-xl mb-5`}>{title}</h1>
        <p css={tw`text-gray-700 text-base font-bold text-sm`}>{description}</p>
      </div>
    </Link>
  );
}

const PostsPage = ({ data, location }: Props) => {
  const lang =
    data.allContentfulBlogPost?.edges[0]?.node?.node_locale || defaultLangKey;
  return (
    <Layout
      lang={lang}
      location={location}
      pathnameWithoutLang={models.static?.blogPosts?.path || ''}
    >
      <SEO
        featuredImage={seoData.posts.featuredImage?.[defaultLangKey]}
        title={seoData.posts.title[defaultLangKey]}
        description={seoData.posts.description[defaultLangKey]}
        lang={defaultLangKey}
        pathname={location.pathname}
      />
      <ContentWidth>
        {data.allContentfulBlogPost.edges.map(({ node }) => (
          <Post node={node} key={node.id} />
        ))}
      </ContentWidth>
    </Layout>
  );
};

export default PostsPage;

export const pageQuery = graphql`
  query blogPostsQuery($lang: String!) {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: { node_locale: { eq: $lang } }
    ) {
      edges {
        node {
          id
          publishDate(formatString: "YYYY/MM/DD")
          node_locale
          title
          slug
          heroImage {
            fluid(maxWidth: 500) {
              aspectRatio
              sizes
              src
              srcSet
            }
          }
          description {
            description
          }
        }
      }
    }
  }
`;
